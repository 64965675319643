import React, { Fragment } from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

// components
import Layout from '../components/Layout'
import Pagina from '../components/Pagina'

const NotFoundPage = ({ data }) => {
  return (
    <Fragment>
      <Layout>
        <Pagina pagina={data.pagina}/>
        <GatsbyImage
          image={data.image404.childImageSharp.gatsbyImageData}
          className='d-none d-lg-block'
          alt='Imagem erro 404' />
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='mt-5'>Ops! Nenhuma página encontrada.</h1>
              <div className='col-12 col-lg-4 p-0 mt-4 mb-5'>
                <Link to='/' className="d-flex align-items-center justify-content-center mr-3 px-4 w-100 btn btn-blue text-uppercase align-middle font-weight-bold">
                  <span className="text-uppercase align-middle font-weight-bold">ir para a home do site</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Fragment>
  );
}

export default NotFoundPage

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/404/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    pageSummary
    tags
    possuiBanner
    card {
      localFile {
        publicURL
      }
    }
  }
  image404: file(relativePath: {eq: "banner-erro.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
